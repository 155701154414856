<div class="top">
    <logo-white class="dsw-logo"></logo-white>
    <button mat-raised-button class="stop-test" *ngIf="testMode" (click)="stopTest()">Stop Test</button>
    <div class="clock">
      <div class="localDate">{{localDate}}</div>
      <div class="localTime">{{localTime}}</div>
    </div>
</div>

<pick-venue *ngIf="!venueIds || venueIds.length == 0"></pick-venue>
<div class="venues">
  <sessions-here [venueId]="venueId" *ngFor="let venueId of venueIds" [ngStyle]="hereSize()"></sessions-here>
</div>  
<sponsorships-slider [sponsorships]="sponsorships" *ngIf="sponsorships"></sponsorships-slider>
