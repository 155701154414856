import { Component, Input } from '@angular/core';
import { User } from '../dsw.service';

@Component({
  selector: 'user-autocomplete',
  templateUrl: './user-autocomplete.component.html',
  styleUrls: ['./user-autocomplete.component.scss']
})
export class UserAutocompleteComponent {
  @Input() user:User;
  
}
