<svg
   width="30px"
   height="30px"
   viewBox="0 0 30 30"
   class="icon"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg">
  <g>
    <circle
       style="fill:#00a300;fill-opacity:1;stroke-width:0.264583"
       cx="15"
       cy="15"
       r="11" />
  </g>
</svg>
