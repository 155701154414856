// Typescript version of vcf written by Jonas Hermsmeier
// https://github.com/jhermsmeier/node-vcf/tree/master

import { Property } from './Property';
// import camelcase from 'camelcase';

export class ParseLines {

  data: { [key: string]: Property | Property[] } = {};

  constructor(lines: string[]) {
    // NOTE: Line format:
    //  PROPERTY[;PARAMETER[=VALUE]]:Attribute[;Attribute]
    var line = null
    var pattern = /^([^;:]+)((?:;(?:[^;:]+))*)(?:\:([\s\S]+))?$/i
    var len = lines.length - 1
    for (var i = 1; i < len; i++) {

      line = lines[i]

      var match = pattern.exec(line)
      if (!match) continue;
      var name = match[1].split('.')
      var property = name.pop()
      var group = name.pop()
      var value = match[3]
      var params = match[2] ? match[2].replace(/^;|;$/g, '').split(';') : []

      var propParams = params.reduce(this.createParams, group ? { group: group } : {})
      var propName = property; // camelcase(property)
      var propVal = new Property(propName, value, propParams)

      this.set(this.data, propName, propVal)

    }
  }
  set(object, key, value) {
    if (Array.isArray(object[key])) {
      object[key].push(value);
    } else if (object[key] != null) {
      object[key] = [object[key], value];
    } else {
      object[key] = value;
    }
  }

  createParams = (params, param) => {

    var parts = param.split('=')
    var k = parts[0]; // camelcase(parts[0])
    var value = parts[1]

    if (value == null || value === '') {
      value = parts[0]
      k = 'type'
    }

    if (k === 'type') {

      if (value[0] === '"' && value[value.length - 1] === '"' && value.indexOf(',') !== -1) {
        value = value.slice(1, -1)
      }
      value.split(',').forEach((value) => {
        this.set(params, k, value)
      })

      return params

    }

    this.set(params, k, value)

    return params

  }

}