import { Component } from '@angular/core';

@Component({
  selector: 'advanced',
  templateUrl: './advanced.component.html',
  styleUrls: ['./advanced.component.scss']
})
export class AdvancedComponent {

}
