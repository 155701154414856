import { Component, ElementRef, Input } from '@angular/core';
import { DswSoModule } from '../dsw-so.module';
import { DswService, VenueLocation } from '../dsw.service';

declare var L : any;

@Component({
  standalone: true,
  imports: [DswSoModule],
  selector: 'venue-location',
  templateUrl: './venue-location.component.html',
  styleUrls: ['./venue-location.component.scss']
})
export class VenueLocationComponent {
  @Input() venue: string;
  @Input() csrf: string;
  location: VenueLocation;
	map: any;
	init = false;
	finalFindUrl: string;
	
  constructor(protected element: ElementRef, public dswService: DswService) {
    this.venue = element.nativeElement.getAttribute("venue");
    this.csrf = element.nativeElement.getAttribute("csrf");
    if (this.venue) {
	    this.dswService.getVenuLocation(this.venue).subscribe((results) => {
				this.location = results;
				this.checkMap();
			});
		}
	}

  displayMap(latLng: string[]) {
    if (!this.map && this.init && this.location) {
      this.map = L.mapquest.map('map', {
        center: latLng,
        layers: L.mapquest.tileLayer('map'),
        zoom: 15
      });   
      let addr = '';
      if (this.location.address) {
        addr += this.location.address;
      }
      if (this.location.suite_or_unit) {
        addr += ', ' + this.location.suite_or_unit;
      }
      L.popup({ closeButton: false })
        .setLatLng(latLng)
        .setContent(
          `<strong>${this.location.name}</strong><br />${addr}`
        )
        .openOn(this.map);
      
      L.marker(latLng, {
            icon: L.mapquest.icons.marker(),
            draggable: false
          }).bindPopup('kkrgr8').addTo(this.map);
      this.map.addControl(L.mapquest.control());    
    }
  }
  
	checkMap() {
		if (!this.map && this.init && this.location && this.location.apiKey) {
			L.mapquest.key = this.location.apiKey;
      if (this.location.lat && this.location.lat.length > 0 && this.location.long  && this.location.long.length) {
          this.displayMap([this.location.lat, this.location.long]);        
      }
      else {
        let addrForMap = this.location.address + ', ' + this.location.city + ', ' + this.location.state;
        L.mapquest.geocoding().geocode(addrForMap, (error, response) => {
          const location = response.results[0].locations[0];
          this.displayMap(location.displayLatLng);
          });
      }
			
		}
		if (this.location && this.location.lookupId) {
      this.finalFindUrl = "https://app.finalfind.me/guest/" + this.location.lookupId;
       
    }
	}

	ngOnInit() {
		this.init = true;
		this.checkMap();
	} 	
}
