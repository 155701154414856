<form [formGroup]="formUser" class="formUser" *ngIf="inAdmin">
   <mat-form-field  class="userName">                 
      <mat-label>User Name</mat-label>
      <input matInput #inputElement placeholder="User Name" [formControl]="userNameFormControl" [matAutocomplete]="nameAutoComplete">
      <mat-autocomplete panelWidth="50em" #nameAutoComplete="matAutocomplete" (optionSelected)="userAutocompleteSelected($event)">
        <mat-option class="search-result" *ngFor="let user of filteredNameChoices | async" [value]="user">
          <user-autocomplete [user]="user"></user-autocomplete>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>  
    <mat-form-field  class="userEmail">                 
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email"  [formControl]="userEmailFormControl" [matAutocomplete]="nameAutoComplete">
    </mat-form-field>
</form>
<form [formGroup]="formCompany" >
  <mat-form-field  class="companyName">                 
      <mat-label>Company Name</mat-label>
      <input matInput #inputElement placeholder="Company name" [formControl]="companyNameFormControl" [matAutocomplete]="companyAutoComplete" (blur)="checkCompanyId($event)">
      <mat-autocomplete panelWidth="50em" #companyAutoComplete="matAutocomplete" (optionSelected)="companyAutocompleteSelected($event)">
        <mat-option class="search-result" *ngFor="let company of filteredCompanyChoices | async" [value]="company">
          {{company.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
</form>
<form [formGroup]="formSpeakerInterest" >
    <input type="hidden"  formControlName="user_id">
    <input type="hidden"  formControlName="company_id">
    <div class="speaker-title">
	    <mat-form-field  class="userTitle">                 
	        <mat-label>Title</mat-label>
	        <input matInput placeholder="Title or Position"  formControlName="title">
	    </mat-form-field>
    </div>
    <div class="speaker-contact">
	    <mat-form-field  class="altEmail">                 
	        <mat-label>Alt. Email</mat-label>
	        <input matInput placeholder="Alternate Email"  formControlName="alt_email">
	    </mat-form-field>
	    <mat-form-field  class="modilePhone">                 
	        <mat-label>Mobile Phone #</mat-label>
	        <input matInput placeholder="Alternate Email"  formControlName="mobile_phone_number">
	    </mat-form-field>
	  </div>
	  <div class="bio">
	    <mat-form-field >
	        <mat-label>Bio</mat-label>
	        <textarea class="TextAreaWorkRequested" matInput formControlName="bio"
	            placeholder ="Please give a synopsis of you experience"></textarea>
	    </mat-form-field>
    </div> 
    <div class="presentation-attributes">
	   <mat-form-field  >                                                            
          <mat-label>Format</mat-label>
	       <mat-select class="mat-select-component" formControlName="format">
	          <mat-option *ngFor="let format of selectValues.formats" [value]="format">{{format}}</mat-option>
	       </mat-select>                                    
	   </mat-form-field>                   
	   <mat-form-field  >                                                            
          <mat-label>Expertise Level</mat-label>
	       <mat-select class="mat-select-component" formControlName="expertise_level">
	          <mat-option *ngFor="let level of selectValues.expertises" [value]="level">{{level}}</mat-option>
	       </mat-select>                                    
	   </mat-form-field>                   
     <mat-form-field  >                                                            
          <mat-label>Track</mat-label>
         <mat-select class="mat-select-component" formControlName="track_id">
            <mat-option *ngFor="let track of selectValues.tracks" [value]="track.id">{{track.name}}</mat-option>
         </mat-select>                                    
     </mat-form-field>                   
	  </div>
    <div class="topic">
	    <mat-form-field >
	        <mat-label>Potential Topic</mat-label>
	        <textarea class="TextAreaWorkRequested" matInput formControlName="potential_topic"
	            placeholder ="Please give a brief desciption of the presentation"></textarea>
	    </mat-form-field> 
    </div>
    <button mat-button color="primary" (click)="showValue()">Update Speaker interest</button>    
</form>
