<svg width="32px" height="32px" viewBox="0 0 26 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="User-Profile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g id="Profile-Linkedin-On" transform="translate(-83.000000, -290.000000)" stroke="#5F5F5F" stroke-width="1.5">
            <g id="Group-14" transform="translate(55.000000, 260.000000)">
                <g id="Group-7" transform="translate(29.000000, 31.000000)">
                    <path d="M18.4,3.16666667 L22.4,3.16666667 C23.28,3.16666667 24,3.87916667 24,4.75 L24,17.4166667 C24,18.2875 23.28,19 22.4,19 L1.6,19 C0.72,19 0,18.2875 0,17.4166667 L0,4.75 C0,3.87916667 0.72,3.16666667 1.6,3.16666667 L5.6,3.16666667" id="Stroke-1"></path>
                    <polyline id="Stroke-3" points="5.6 3.16666667 7.1968 0 16.8048 0 18.4 3.16666667"></polyline>
                    <path d="M16.8,10.2916667 C16.8,7.668875 14.6512,5.54166667 12,5.54166667 C9.3496,5.54166667 7.2,7.668875 7.2,10.2916667 C7.2,12.9144583 9.3496,15.0416667 12,15.0416667 C14.6512,15.0416667 16.8,12.9144583 16.8,10.2916667 Z" id="Stroke-5"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
