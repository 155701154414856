import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'create-company-dialog',
  templateUrl: './create-company-dialog.component.html',
  styleUrls: ['./create-company-dialog.component.scss']
})
export class CreateCompanyDialogComponent {
  constructor(public dialogRef: MatDialogRef<CreateCompanyDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log("In dialog constructor", data);
  }
  
  cancel() {
   this.dialogRef.close();
  }
}
