import { Component, Input, HostBinding } from '@angular/core';

import { Sponsorship, Sponsor, Track } from '../dsw.service';

@Component({
  selector: 'sponsorships-slide',
  templateUrl: './sponsorships-slide.component.html',
  styleUrls: ['./sponsorships-slide.component.scss']
})
export class SponsorshipsSlideComponent {
  @Input() sponsorship: Sponsorship;
  @Input() display: boolean;
  
  @HostBinding('style.background') get slideBackground() {
    if (this.sponsorship.sponsors) {
      return 'white';
    }
    return 'transparent';
  }
  
  @HostBinding('style.display') get slideDisplay() {
    if (this.display) {
      return 'flex';
    }
    return 'none';
  }
  
  constructor() { }
  logoClass(track: Track) {
    if (track) {
      return track.name.toLowerCase() + '-icon';
    }
    return '';
  }
  
  logoUrl(sponsor: Sponsor) {
    let logo = sponsor.logo;
    if (logo && !logo.includes("cloudfront")) {
      logo = "https://dbiqieltvc37u.cloudfront.net" + logo;
    }
    return  logo;
  }

}
