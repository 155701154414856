// Typescript version of vcf written by Jonas Hermsmeier
// https://github.com/jhermsmeier/node-vcf/tree/master

export class Property {
  TYPE: string;
  data: any;
  field: string;
  group: string;
  value: any;

  constructor(field: string, value: any, params?: any) {
    if (params)
      Object.assign(this, params)

    this.field = field
    this.data = value
  }

  static fromJSON(data): Property {

    var field = data[0];
    var params = data[1];

    if (!/text/i.test(data[2])) {
      params.value = data[2];
    }
    var value = Array.isArray(data[3]) ?
      data[3].join(';') : data[3];

    return new Property(field, value, params);

  }

  is(type: string) {
    return Array.isArray(this.TYPE) ?
      this.TYPE.indexOf(type) >= 0 :
      this.TYPE === type;
  }

  /**
   * Check whether the property is empty
   * @return {Boolean}
   */
  isEmpty() {
    return this.data == null &&
      Object.keys(this).length === 0;
  }

  clone() {
    return new Property(this.field, this.data, this);
  }

  toString(version: string) {

    var propName = (this.group ? this.group + '.' : '') + this.field;
    var keys = Object.keys(this);
    var params = [];

    Object.entries(this).forEach(([key, value]) => {
      if (key === 'group' || key === 'data' || key === 'field' || !value) {
        return;
      }
      switch (propName) {
        case 'TEL':
        case 'ADR':
        case 'EMAIL':
          if (version === '2.1') {
            if (Array.isArray(this[key]))
              params.push(this[key].join(';'));
            else
              params.push(value);
          }
          else
            params.push(key + '=' + value);
          break
        default:
          params.push(key + '=' + value);
      }
    });

    if (version === '2.1' || version === '3.0')
      return propName +
        (params.length ? ';' + params.join(';').toUpperCase() : params.toString().toUpperCase()) + ':' +
        (Array.isArray(this.data) ? this.data.join(';') : this.data);
    else
      return propName +
        (params.length ? ';' + params.join(';') : params) + ':' +
        (Array.isArray(this.data) ? this.data.join(';') : this.data);

  }

  valueOf() {
    return this.data;
  }

//  capitalDashCase(value) {
//    return value.replace(/([A-Z])/g, '-$1').toUpperCase();
//  }

  toJSON() {

    var params: Property = Object.assign({}, this);

    if (params.value === 'text') {
      params.value = void 0;
      delete params.value;
    }

    var data = [this.field, params, this.value || 'text'];

    switch (this.field) {
      default: data.push(this.data); break
      case 'ADR':
      case 'A':
        data.push(this.data.split(';'));
    }

    return data;

  }

}