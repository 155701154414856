import { Component } from '@angular/core';

@Component({
  selector: 'image-placeholder',
  templateUrl: './image-placeholder.component.html',
  styleUrls: ['./image-placeholder.component.scss']
})
export class ImagePlaceholderComponent {

}
