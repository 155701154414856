import { Component } from '@angular/core';

@Component({
  selector: 'beginner',
  templateUrl: './beginner.component.html',
  styleUrls: ['./beginner.component.scss']
})
export class BeginnerComponent {

}
