import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'developer',
  templateUrl: './developer.component.html',
  styleUrls: ['./developer.component.scss']
})
export class DeveloperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
