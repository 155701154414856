<svg width="188px" height="43px" viewBox="0 0 188 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: sketchtool 54.1 (76490) - https://sketchapp.com -->
    <title>296C8999-BE58-4CBB-BD8C-FF26794C6BBD@1.00x</title>
    <desc>Created with sketchtool.</desc>
    <defs>
        <polygon id="path-1" points="0.53490355 0.543230769 20.2023107 0.543230769 20.2023107 25.8601538 0.53490355 25.8601538"></polygon>
        <polygon id="path-3" points="0.21774142 0.559758974 4.83426213 0.559758974 4.83426213 7.33333333 0.21774142 7.33333333"></polygon>
        <polygon id="path-5" points="0.391923373 0.0623897436 5.36631391 0.0623897436 5.36631391 6.76923077 0.391923373 6.76923077"></polygon>
        <polygon id="path-7" points="0.00011183432 0.54334359 31.4937746 0.54334359 31.4937746 42.3076923 0.00011183432 42.3076923"></polygon>
        <polygon id="path-9" points="0.316826627 0.0739538462 39.6277083 0.0739538462 39.6277083 35.5384615 0.316826627 35.5384615"></polygon>
        <polygon id="path-11" points="0.000279585799 0.0688769231 35.9835311 0.0688769231 35.9835311 22 0.000279585799 22"></polygon>
        <polygon id="path-13" points="0.180947929 0.114738462 24.3461077 0.114738462 24.3461077 35.5384615 0.180947929 35.5384615"></polygon>
        <polygon id="path-15" points="0.0505491124 0.0740102564 31.5570728 0.0740102564 31.5570728 35.5384615 0.0505491124 35.5384615"></polygon>
    </defs>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Logo---Horz-Wht">
            <g>
                <path d="M128.032962,13.2228462 C128.032962,7.64048718 124.543172,3.37361538 118.727228,3.37361538 L114.39197,3.37361538 L114.39197,23.0720769 L118.727228,23.0720769 C124.543172,23.0720769 128.032962,18.7696667 128.032962,13.2228462 M130.993216,13.2228462 C130.993216,20.1562308 125.84716,25.6680769 118.727228,25.6680769 L111.396488,25.6680769 L111.396488,0.777615385 L118.727228,0.777615385 C125.84716,0.777615385 130.993216,6.28889744 130.993216,13.2228462" id="Fill-1" fill="#FFFFFF"></path>
                <polygon id="Fill-3" fill="#FFFFFF" points="157.358604 23.001 157.358604 25.6680769 141.568157 25.6680769 141.568157 0.777615385 156.93587 0.777615385 156.93587 3.44412821 144.634654 3.44412821 144.634654 11.8362821 153.481308 11.8362821 153.481308 14.5027949 144.634654 14.5027949 144.634654 23.001"></polygon>
                <g id="Group-7" transform="translate(167.751479, 0.020872)">
                    <mask id="mask-2" fill="white">
                        <use xlink:href="#path-1"></use>
                    </mask>
                    <g id="Clip-6"></g>
                    <polygon id="Fill-5" fill="#FFFFFF" mask="url(#mask-2)" points="20.2026462 25.8601538 20.0969627 25.8601538 3.53094497 7.44164103 3.53094497 25.6469231 0.53490355 25.6469231 0.53490355 0.543230769 0.640586982 0.543230769 17.2066047 19.0328205 17.2066047 0.757025641 20.2026462 0.757025641"></polygon>
                </g>
                <g id="Group-10" transform="translate(110.156805, 34.995231)">
                    <mask id="mask-4" fill="white">
                        <use xlink:href="#path-3"></use>
                    </mask>
                    <g id="Clip-9"></g>
                    <path d="M4.74982722,1.92601538 L4.24209941,2.11555385 C3.99774142,1.56498974 3.41452544,1.08155385 2.56793964,1.08155385 C1.6749426,1.08155385 1.08277988,1.56498974 1.08277988,2.21991282 C1.08277988,2.89401538 1.53403136,3.14955385 2.21062899,3.38704103 L3.00073846,3.66175897 C4.03520592,4.02278462 4.83426213,4.50622051 4.83426213,5.5594 C4.83426213,6.6599641 3.71535976,7.33350256 2.56793964,7.33350256 C1.47755503,7.33350256 0.528081657,6.68760513 0.21774142,5.77714359 L0.725469231,5.5594 C0.988839053,6.28032308 1.68444852,6.81114359 2.56793964,6.81114359 C3.4430432,6.81114359 4.24209941,6.35647692 4.24209941,5.5594 C4.24209941,4.75329744 3.6024071,4.4588359 2.88834497,4.21232308 L2.07027692,3.92745128 C1.15826805,3.60534872 0.490057988,3.16873333 0.490057988,2.21991282 C0.490057988,1.29027179 1.38305503,0.559758974 2.56793964,0.559758974 C3.66838935,0.559758974 4.43948698,1.21468205 4.74982722,1.92601538" id="Fill-8" fill="#FFFFFF" mask="url(#mask-4)"></path>
                </g>
                <polygon id="Fill-11" fill="#FFFFFF" points="121.222978 36.1527692 119.258049 36.1527692 119.258049 42.262 118.656381 42.262 118.656381 36.1527692 116.681387 36.1527692 116.681387 35.6219487 119.258049 35.6219487 121.222978 35.6219487"></polygon>
                <path d="M126.383573,39.8144718 L125.029819,36.7220615 L123.685011,39.8144718 L126.383573,39.8144718 Z M126.599972,40.3176513 L123.468612,40.3176513 L122.622026,42.2621128 L121.973387,42.2621128 L125.001301,35.5459077 L125.057777,35.5459077 L128.085691,42.2621128 L127.445999,42.2621128 L126.599972,40.3176513 Z" id="Fill-13" fill="#FFFFFF"></path>
                <path d="M130.601908,39.2454051 L132.153609,39.2454051 C133.216035,39.2454051 133.817704,38.6672 133.817704,37.6896103 C133.817704,36.7125846 133.216035,36.1529949 132.153609,36.1529949 L130.601908,36.1529949 L130.601908,39.2454051 Z M134.109591,42.2622256 L132.313532,39.7576103 L132.153609,39.7576103 L130.601908,39.7576103 L130.601908,42.2622256 L130.009186,42.2622256 L130.009186,35.6216103 L132.153609,35.6216103 C133.554893,35.6216103 134.410425,36.5230462 134.410425,37.6896103 C134.410425,38.6384308 133.855168,39.4067385 132.896189,39.662841 L134.777242,42.2622256 L134.109591,42.2622256 Z" id="Fill-15" fill="#FFFFFF"></path>
                <polygon id="Fill-17" fill="#FFFFFF" points="140.820713 36.1527692 138.855784 36.1527692 138.855784 42.262 138.254115 42.262 138.254115 36.1527692 136.279121 36.1527692 136.279121 35.6219487 138.855784 35.6219487 140.820713 35.6219487"></polygon>
                <g id="Group-21" transform="translate(142.588757, 35.559333)">
                    <mask id="mask-6" fill="white">
                        <use xlink:href="#path-5"></use>
                    </mask>
                    <g id="Clip-20"></g>
                    <path d="M5.36631391,4.19838974 C5.36631391,5.80156923 4.3228997,6.76956923 2.87464527,6.76956923 C1.42639083,6.76956923 0.391923373,5.80156923 0.391923373,4.19838974 L0.391923373,0.0623897436 L0.975139349,0.0623897436 L0.975139349,4.19838974 C0.975139349,5.52628718 1.72722515,6.24721026 2.87464527,6.24721026 C4.02150621,6.24721026 4.7836571,5.52628718 4.7836571,4.19838974 L4.7836571,0.0623897436 L5.36631391,0.0623897436 L5.36631391,4.19838974 Z" id="Fill-19" fill="#FFFFFF" mask="url(#mask-6)"></path>
                </g>
                <path d="M153.970528,37.6897231 C153.970528,36.7126974 153.3683,36.1525436 152.305874,36.1525436 L150.754172,36.1525436 L150.754172,39.2455179 L152.305874,39.2455179 C153.3683,39.2455179 153.970528,38.6667487 153.970528,37.6897231 M154.56269,37.6897231 C154.56269,38.8658769 153.707158,39.7577231 152.305874,39.7577231 L150.754172,39.7577231 L150.754172,42.2617744 L150.161451,42.2617744 L150.161451,35.6217231 L152.305874,35.6217231 C153.707158,35.6217231 154.56269,36.523159 154.56269,37.6897231" id="Fill-22" fill="#FFFFFF"></path>
                <polygon id="Fill-24" fill="#FFFFFF" points="165.45759 42.262 165.325625 42.262 163.228732 36.8646667 161.112826 42.262 160.981421 42.262 158.743616 35.6219487 159.363738 35.6219487 161.093814 40.8579487 163.153803 35.6219487 163.294714 35.6219487 165.344637 40.8579487 167.074714 35.6219487 167.695394 35.6219487"></polygon>
                <polygon id="Fill-26" fill="#FFFFFF" points="173.822238 41.7215897 173.822238 42.262 169.712886 42.262 169.712886 35.6219487 173.709844 35.6219487 173.709844 36.1629231 170.324619 36.1629231 170.324619 38.6568205 172.78777 38.6568205 172.78777 39.1887692 170.324619 39.1887692 170.324619 41.7215897"></polygon>
                <polygon id="Fill-28" fill="#FFFFFF" points="180.138584 41.7215897 180.138584 42.262 176.029232 42.262 176.029232 35.6219487 180.026191 35.6219487 180.026191 36.1629231 176.640966 36.1629231 176.640966 38.6568205 179.104117 38.6568205 179.104117 39.1887692 176.640966 39.1887692 176.640966 41.7215897"></polygon>
                <polygon id="Fill-30" fill="#FFFFFF" points="184.715404 38.8371641 187.912747 42.2618308 187.160102 42.2618308 184.349146 39.2359846 183.088214 40.5548564 183.088214 42.2618308 182.486546 42.2618308 182.486546 35.6217795 183.088214 35.6217795 183.088214 39.7577795 186.962714 35.6217795 187.715359 35.6217795"></polygon>
                <g id="Group-34" transform="translate(0.000000, 0.020872)">
                    <mask id="mask-8" fill="white">
                        <use xlink:href="#path-7"></use>
                    </mask>
                    <g id="Clip-33"></g>
                    <polygon id="Fill-32" fill="#4FE4E7" mask="url(#mask-8)" points="0.00011183432 42.3078051 28.1398633 20.3766256 31.4937746 0.54334359"></polygon>
                </g>
                <g id="Group-37" transform="translate(61.508876, 6.790103)">
                    <mask id="mask-10" fill="white">
                        <use xlink:href="#path-9"></use>
                    </mask>
                    <g id="Clip-36"></g>
                    <polygon id="Fill-35" fill="#4FE4E7" mask="url(#mask-10)" points="0.316826627 0.0739538462 23.7287822 35.5385179 30.4410781 35.5385179 39.6277083 35.5385179"></polygon>
                </g>
                <path d="M41.4880163,30.5882923 L41.4880163,28.3820872 C41.4880163,28.3820872 41.3280932,25.9987538 42.911108,24.2144974 L36.7087766,22.6118821 L36.9816524,27.0474205 L39.2736967,27.0474205 L39.2736967,31.7604974 L41.4880163,30.5882923 Z" id="Fill-38" fill="#91ECEF"></path>
                <g id="Group-42" transform="translate(0.000000, 20.328564)">
                    <mask id="mask-12" fill="white">
                        <use xlink:href="#path-11"></use>
                    </mask>
                    <g id="Clip-41"></g>
                    <polygon id="Fill-40" fill="#91ECEF" mask="url(#mask-12)" points="22.2100163 17.4189795 22.2100163 11.9505692 29.7884689 11.9505692 29.7884689 16.4538 32.7956938 14.8619026 32.7956938 6.71851795 35.6989127 6.71851795 35.9835311 2.09626154 28.1400311 0.0688769231 0.000279585799 22.0000564 19.3123891 22.0000564 22.2100163 20.4656974"></polygon>
                </g>
                <path d="M36.0653379,21.0977179 L36.6150036,21.0977179 L36.7083852,22.6123333 L42.9112757,24.2143846 C43.8076278,23.1634615 45.2659473,22.3263333 47.6407491,22.3263333 L47.6407491,24.5974103 C47.6407491,24.5974103 49.3328024,24.9415128 50.4438763,26.0849487 L73.1221988,27.5865897 L31.4941101,0.564384615 L28.1401988,20.3976667 L35.9836988,22.4250513 L36.0653379,21.0977179 Z" id="Fill-43" fill="#C2F4F4"></path>
                <polygon id="Fill-45" fill="#AFF2F3" points="32.7954701 31.0896103 29.7882453 33.5964821 29.7882453 37.7477128 32.7954701 37.7477128"></polygon>
                <g id="Group-49" transform="translate(37.464497, 6.790103)">
                    <mask id="mask-14" fill="white">
                        <use xlink:href="#path-13"></use>
                    </mask>
                    <g id="Clip-48"></g>
                    <path d="M1.80925562,20.2571487 L1.80925562,35.5386872 L4.02357515,35.5386872 L4.02357515,35.5217641 L4.02357515,28.717559 L4.02357515,21.5918154 C4.02357515,21.5918154 3.78760473,15.5361744 10.1761402,15.5361744 L10.1761402,17.8072513 C10.1761402,17.8072513 12.3138533,18.2393538 13.3623,19.752841 C13.7017172,20.2453026 13.9270633,20.8505846 13.9270633,21.5918154 L13.9270633,22.4943795 L13.9270633,28.4704821 L16.1732556,22.3573026 L16.1732556,21.7898154 L16.3818266,21.7898154 L24.3461077,0.114738462 L0.180947929,20.2571487 L1.80925562,20.2571487 Z" id="Fill-47" fill="#AFF2F3" mask="url(#mask-14)"></path>
                </g>
                <polygon id="Fill-50" fill="#AFF2F3" points="22.2100163 39.9130769 19.3123891 42.3285641 22.2100163 42.3285641"></polygon>
                <g id="Group-54" transform="translate(53.680473, 6.790103)">
                    <mask id="mask-16" fill="white">
                        <use xlink:href="#path-15"></use>
                    </mask>
                    <g id="Clip-53"></g>
                    <polygon id="Fill-52" fill="#90ECEF" mask="url(#mask-16)" points="8.09255503 21.7897026 8.09255503 29.5946256 8.09255503 30.9574974 22.7305491 29.3729333 19.1837237 35.5385744 31.5570728 35.5385744 8.14511716 0.0740102564 0.0505491124 21.7897026"></polygon>
                </g>
                <polygon id="Fill-55" fill="#90ECEF" points="53.6377527 39.2624974 53.6377527 28.8288564 51.3915604 34.8546 51.3915604 39.2624974"></polygon>
            </g>
        </g>
    </g>
</svg>