<ng-container *ngIf="sponsorship.sponsors">
  <h1>{{sponsorship.level}} SPONSORS</h1>
  <div class="sponsors">
	  <div class="sponsor" *ngFor="let sponsor of sponsorship.sponsors"  >
	    <div class="sponsorLogo" [ngStyle]="sponsorship.sponsorDivStyle">
  	    <img [src]="logoUrl(sponsor)"/>
	    </div>
	    <div class="track" *ngIf="sponsor.track">
	      <track-icon [track]="sponsor.track" [ngClass]="logoClass(sponsor.track)"></track-icon>
	      <div class="track-name">{{sponsor.track.name}}</div>
	    </div>
	  </div>
  </div>
</ng-container>
<ng-container *ngIf="!sponsorship.sponsors">
  <div class="links">
    <div class="instructions">
      <div>TO REGISTER GOTO:</div>
      <a href="https://www.denverstartupweek.org/register">https://www.denverstartupweek.org/register</a>
    </div>
		<qrcode qrdata="https://www.denverstartupweek.org/register" [width]="120" [errorCorrectionLevel]="'M'"></qrcode>

    <div class="instructions">
      <div>CHECK SCHEDULE AT:</div>
      <a href="https://www.denverstartupweek.org/schedule">https://www.denverstartupweek.org/schedule</a>
    </div>
		<qrcode qrdata="https://www.denverstartupweek.org/schedule" [width]="120" [errorCorrectionLevel]="'M'"></qrcode>
  </div>
</ng-container>