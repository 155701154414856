import { Component } from '@angular/core';

@Component({
  selector: 'intermediate',
  templateUrl: './intermediate.component.html',
  styleUrls: ['./intermediate.component.scss']
})
export class IntermediateComponent {

}
