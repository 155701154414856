import { Component } from '@angular/core';

@Component({
  selector: 'app-martini',
  templateUrl: './martini.component.html',
  styleUrls: ['./martini.component.scss']
})
export class MartiniComponent {

  constructor() { }

}
