import { Component, Inject, Input, HostBinding, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { VCardProperty, VCard } from '../vcard/Vcard';
import { Property } from '../vcard/Property';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DswService } from '../dsw.service';

@Component({
  selector: 'edit-vcard',
  templateUrl: './edit-vcard.component.html',
  styleUrls: ['./edit-vcard.component.scss']
})
export class EditVcardComponent {
  parsedVcard: VCard;
  _vcard: string;
  avatar: string;
  generated = false;
  inDrop = false;
  inDrag = false;
  @Input()
  set vcard(value: string) {
    this._vcard = this.checkIdiotLineEndings(value);
    let photoProperty:Property; 
    if (this.parsedVcard) {
			photoProperty = this.getFirstProperty("PHOTO");
		}
    this.parsedVcard = new VCard().parse(this._vcard);
		if (photoProperty && !this.getFirstProperty("PHOTO")) {
			this.parsedVcard.addProperty(photoProperty);
		}
    console.log("Parsed card", this.parsedVcard, this.parsedVcard.format('3.0'));
    
    console.log("get ", this.parsedVcard.get("fn"));
    this.mapCardToFields();
  }

  get vcard(): string {
    return this._vcard;
  }
  @HostBinding('class') get cssClass() {
    let cssClass = '';
    if (this.inDrop) {
      cssClass += " file-in-drop";
    }
    if (this.inDrag) {
      cssClass += " file-in-drag";
    }
    return cssClass;
  }  
  formVCard: FormGroup;

  csrf: string;
  get controls() {
    return this.formVCard.controls;
  }


  addrControls: string[] = [
    'poBox',
    'address',
    'address2',
    'city',
    'state',
    'zipCode',
    'country'
  ];

  nameControls: string[] = [
    'lastName',
    'givenName',
    'middleName',
    'prefix',
    'suffix'
  ]

  fullNameControl: string[] = ['fullName'];
  titleControl: string[] = ['title'];
  orgControl: string[] = ['org'];
  emailControls: string[] = ['email1', 'email2'];
  urlControls: string[] = ['url1', 'url2'];

  getFirstProperty(propertyName: string) {
    let adrVCardProperty: VCardProperty = this.parsedVcard.get(propertyName);

    if (Array.isArray(adrVCardProperty)) {
      return (adrVCardProperty as Property[])[0];
    }
    return (adrVCardProperty as Property);
  }

  mapSemiDelimCadToFields(propertyName: string, fieldNames: string[]) {
    let semiPropery: Property = this.getFirstProperty(propertyName);
    console.log(propertyName + " Semi Property", semiPropery);
    if (semiPropery) {
      let adrFields: string[] = semiPropery.data.split(';');
      console.log(propertyName + " Fields", semiPropery.data);
      for (let i = 0; i < fieldNames.length; i++) {
        if (adrFields.length > i && adrFields[i] && adrFields[i].length > 0) {
          this.controls[fieldNames[i]].setValue(adrFields[i]);
        }
      }
    }
  }

  mapSemiDelimFieldsToCard(propertyName: string, fieldNames: string[]) {
    let semiPropery: Property = this.getFirstProperty(propertyName);
    if (semiPropery) {
      console.log("b4 save", semiPropery.data);
      let adrFields: string[] = semiPropery.data.split(';');
      for (let i = 0; i < fieldNames.length; i++) {
        if (adrFields.length > i) {
          adrFields[i] = this.controls[fieldNames[i]].value;
        }
        else {
          adrFields.push(this.controls[fieldNames[i]].value);
        }
      }
      semiPropery.data = adrFields.join(';');
      console.log("AdrFields After", semiPropery.data);

    }
    else {
      let create = false;
      for (let i = 0; i < fieldNames.length; i++) {
        if (this.controls[fieldNames[i]].value && this.controls[fieldNames[i]].value.length > 0) {
          create = true;
          break;
        }
      }
      if (create) {
        let adrFields: string[] = [];
        for (let i = 0; i < fieldNames.length; i++) {
          adrFields.push(this.controls[fieldNames[i]].value);
        }
        this.parsedVcard.add(propertyName, adrFields.join(';'));
      }
    }
  }

  checkGenerated() {
    let adrVCardProperty: VCardProperty = this.parsedVcard.get("NOTE");
    console.log("NOTE vCardProperty", adrVCardProperty);
    if (adrVCardProperty) {
      if (Array.isArray(adrVCardProperty)) {
        let properties: Property[] = (adrVCardProperty as Property[]);
        let index = -1;
        for (let i = 0; i < properties.length; i++) {
          if (properties[i].data == 'DSWGenerated') {
            index = i;
            break;
          }
        }
        if (index = -1) {
          properties.splice(index, 1);
          this.generated = true;
        }
      }
      else {
        let property: Property = (adrVCardProperty as Property);
        if (property.data == 'DSWGenerated') {
          this.generated = true;
          delete this.parsedVcard.data['NOTE'];
        }
      }
    }

  }
  mapCardValueToFields(propertyName: string, fieldNames: string[]) {
    let adrVCardProperty: VCardProperty = this.parsedVcard.get(propertyName);
    console.log("vCardProperty", propertyName, adrVCardProperty);
    if (adrVCardProperty) {
      if (Array.isArray(adrVCardProperty) && fieldNames.length > 1) {
        let properties: Property[] = (adrVCardProperty as Property[]);

        for (let i = 0; i < fieldNames.length; i++) {
          if (properties.length > i && properties[i].data) {
            this.controls[fieldNames[i]].setValue(properties[i].data);
          }
        }
      }
      else {
        let property: Property = (adrVCardProperty as Property);
        this.controls[fieldNames[0]].setValue(property.data);

      }
    }
  }

  mapFieldsToCardValue(propertyName: string, fieldNames: string[]) {
    let adrVCardProperty: VCardProperty = this.parsedVcard.get(propertyName);
    if (adrVCardProperty) {
      if (Array.isArray(adrVCardProperty) && fieldNames.length > 1) {
        let properties: Property[] = (adrVCardProperty as Property[]);

        for (let i = 0; i < fieldNames.length; i++) {
          if (properties.length > i) {
            properties[i].data = this.controls[fieldNames[i]].value;
          }
          else {
            this.parsedVcard.add(propertyName, this.controls[fieldNames[i]].value);
          }
        }
      }
      else {
        let property: Property = (adrVCardProperty as Property);
        property.data = this.controls[fieldNames[0]].value;
      }
    }
    else {
      for (let i = 0; i < fieldNames.length; i++) {
        if (this.controls[fieldNames[i]].value && this.controls[fieldNames[i]].value.length > 0) {
          this.parsedVcard.add(propertyName, this.controls[fieldNames[i]].value);
        }
      }
    }
  }

  mapCardToFields() {
    if (!this.formVCard || !this.parsedVcard) {
      console.log("Not ready to map", this.formVCard, this.parsedVcard);
      return;
    }
    this.checkGenerated();
    let semiPropery: Property = this.getFirstProperty("PHOTO");
    if (semiPropery) {
      this.avatar = semiPropery.data;
    }
    this.mapSemiDelimCadToFields("ADR", this.addrControls);
    this.mapSemiDelimCadToFields("N", this.nameControls);
    this.mapCardValueToFields("FN", this.fullNameControl);
    this.mapCardValueToFields("TITLE", this.titleControl);
    this.mapCardValueToFields("ORG", this.orgControl);
    this.mapCardValueToFields("EMAIL", this.emailControls);
    this.mapCardValueToFields("URL", this.urlControls);
  }

  mapFieldsToCard() {
    this.mapSemiDelimFieldsToCard("ADR", this.addrControls);
    this.mapSemiDelimFieldsToCard("N", this.nameControls);
    this.mapFieldsToCardValue("FN", this.fullNameControl);
    this.mapFieldsToCardValue("TITLE", this.titleControl);
    this.mapFieldsToCardValue("ORG", this.orgControl);
    this.mapFieldsToCardValue("EMAIL", this.emailControls);
    this.mapFieldsToCardValue("URL", this.urlControls);
  }
  checkIdiotLineEndings(stupidHumansWhoLikeWindowsLineEnding: string) {
    if (stupidHumansWhoLikeWindowsLineEnding && stupidHumansWhoLikeWindowsLineEnding.indexOf("\r\n") == -1) {
      return stupidHumansWhoLikeWindowsLineEnding.replaceAll("\n", "\r\n");
    }
    return stupidHumansWhoLikeWindowsLineEnding;
  }
  constructor(public dialogRef: MatDialogRef<EditVcardComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public dswService: DswService, public formBuilder: FormBuilder,) {
    console.log("In dialog constructor", data);
    this.vcard = data['vcard'];
    this.csrf = data['csrf'];
    this.formVCard = this.formBuilder.group({
      fullName: [''],
      company: [''],
      title: [''],
      org: [''],
      email1: [''],
      email2: [''],
      url1: [''],
      url2: [''],
      prefix: [''],
      lastName: [''],
      givenName: [''],
      middleName: [''],
      suffix: [''],
      poBox: [''],
      address: [''],
      address2: [''],
      city: [''],
      state: [''],
      zipCode: [''],
      country: ['']
    });
  }

  ngOnInit() {
    console.log("In init");
    this.mapCardToFields();
  }

  updateCard() {
    console.log("updateCard", this.parsedVcard);
    this.mapFieldsToCard();
    this.dialogRef.close(this.parsedVcard.format());
  }
  resetCard() {
    console.log("updateCard", this.parsedVcard);
    this.dialogRef.close('RESET');
  }
  cancel() {
    console.log("canel");
    this.dialogRef.close();
  }

 @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.inDrop = true;
    event.preventDefault();
  }
//   @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
//    this.inDrop = true;
//    event.preventDefault();
//  }
  @HostListener("dragstart", ["$event"]) onDrag(event: any) {
    this.inDrag  = true;
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.inDrag  = false;
    this.inDrop = false;
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.inDrop = false;
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.inDrag  = false;
    this.inDrop  = false;
    event.preventDefault();
    event.stopPropagation();
    console.log("Drop file");
    if (event.dataTransfer.files) {
      this.loadFile(event.dataTransfer.files[0]);
    }
  }

  FileChange(event: any) {
    let files: FileList = event.target.files;
    this.loadFile(event.target.files[0]);
  }
  
  loadFile(file: File) {
      file.text().then((fileContents) => {
        console.log("File contents", fileContents);
        if (fileContents.startsWith("BEGIN:VCARD")) {
          this.vcard = fileContents;
        }
      });
    }
}
