import { Component, Input, ElementRef } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Observable, Subscription } from 'rxjs';
import { DswService } from '../dsw.service';
import { EditVcardComponent } from '../edit-vcard/edit-vcard.component';

import { DswSoModule } from '../dsw-so.module';

@Component({
  standalone: true,
  imports: [DswSoModule],
  selector: 'user-qr-vcard',
  templateUrl: './user-qr-vcard.component.html',
  styleUrls: ['./user-qr-vcard.component.scss']
})
export class UserQrVcardComponent {
  @Input() url: string;
  @Input() csrf: string;
  vcard: string;
  constructor(public element: ElementRef, public meta: Meta, public dswService: DswService, public dialog: MatDialog) {
    this.url = element.nativeElement.getAttribute("url");
    this.csrf = element.nativeElement.getAttribute("csrf");
  }
  
  dialogRef: MatDialogRef<EditVcardComponent, any>;
  dialogCloseSubscription: Subscription;
  
  openDialog() {
    let config: MatDialogConfig = {
      data: {csrf: this.csrf, vcard: this.vcard},
      maxHeight: '95vh',
      maxWidth: '95vw'
    };
    this.dialog.closeAll();
    let dialogRef: MatDialogRef<EditVcardComponent, any> = this.dialog.open(EditVcardComponent, config);
    this.dialogCloseSubscription = dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`); // Pizza!
      if (result) {
        if (result != 'RESET') {
          this.vcard = result;
        }
        else {
          this.vcard = undefined;
        }
        this.dswService.postVCard(this.url, this.vcard, this.csrf).subscribe((response) => {
          console.log("Get vcard", response);
        });
        this.dialogCloseSubscription.unsubscribe();
        this.dialogCloseSubscription = undefined;
      }
    });

  }
  openCard() {
    if (this.url && !this.vcard) {
      this.dswService.getVCard(this.url).subscribe((response: any) => {
        console.log("Get vcard", response);
        this.vcard = response;
        this.openDialog();
      });
    }
    else {
      this.openDialog();
    }
  
  }  
}
