import { Component } from '@angular/core';

@Component({
  selector: 'logo-white',
  templateUrl: './logo-white.component.html',
  styleUrls: ['./logo-white.component.scss']
})
export class LogoWhiteComponent {

}
