import { Component, Input, ElementRef } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

import { Observable, Subscription } from 'rxjs';

import { DswSoModule } from '../dsw-so.module';
import { DswService, SpeakerInterest, SelectValues, User, Company } from '../dsw.service';
import { CreateCompanyDialogComponent } from '../create-company-dialog/create-company-dialog.component';

@Component({
  standalone: true,
  imports: [DswSoModule],
  selector: 'speaker-interests-form',
  templateUrl: './speaker-interests-form.component.html',
  styleUrls: ['./speaker-interests-form.component.scss']
})
export class SpeakerInterestsFormComponent {
  private _id: string;
  @Input() set id(value: string) {
    this._id = value;
    console.log("Set ID", value);
    this.loadFormData();
  }
  get id(): string {
    return this._id;
  }
  private _csrf: string;
  @Input() set csrf(value: string) {
    console.log("Set csrf", value);
    this._csrf = value;
    this.loadFormData();
  }
  get csrf(): string {
    return this._csrf;
  }
  inAdmin: boolean;
  submitUrl: string;
  speakerInterst: SpeakerInterest;
  formUser: FormGroup;
  userNameFormControl: FormControl;
  userEmailFormControl: FormControl;
  companyNameFormControl: FormControl;
  userSearchDelay: any;
  companySearchDelay: any;
  nameChangeObserverSubscription: Subscription;
  emailChangeObserverSubscription: Subscription;
  companyNameChangeObserverSubscription: Subscription;
  filteredNameChoices?: Observable<User[]>;
  filteredCompanyChoices?: Observable<Company[]>;
  formCompany: FormGroup;
  formSpeakerInterest: FormGroup;
  selectValues: SelectValues = {
    expertises: [],
    formats: [],
    tracks: []
  }
  constructor(public element: ElementRef, public meta: Meta, public dswService: DswService, public formBuilder: FormBuilder, public location: Location, public dialog: MatDialog) {
    console.log("In constructor", this.element, this.element.nativeElement);
    this.element.nativeElement.fin
    //    let value = meta.getTag("name='speaker_interest'");
    //    this.id = value.getAttribute("content");
    this.id = element.nativeElement.getAttribute("id");
    this.csrf = element.nativeElement.getAttribute("csrf");
    this.inAdmin = element.nativeElement.getAttribute("in-admin");
    this.submitUrl = element.nativeElement.getAttribute("submit");
    //    let value = meta.getTag("name='csrf-token'");
    //    this.csrf  = value.getAttribute("content");
    console.log("id: ", this.id, " csrf", this.csrf, " in admin", this.inAdmin);

    this.userNameFormControl = new FormControl('');
    this.nameChangeObserverSubscription = this.userNameFormControl.valueChanges.subscribe((searchText) => this.triggerNameSearch(searchText));

    this.userEmailFormControl = new FormControl('');
    this.emailChangeObserverSubscription = this.userEmailFormControl.valueChanges.subscribe((searchText) => this.triggerNameSearch(searchText));

    this.formUser = this.formBuilder.group({
      name: this.userNameFormControl,
      email: this.userEmailFormControl
    });
    // this.formUser.disable();
    this.companyNameFormControl = new FormControl('');
    this.companyNameChangeObserverSubscription = this.companyNameFormControl.valueChanges.subscribe((searchText) => this.triggerCompanySearch(searchText));
    this.formCompany = this.formBuilder.group({
      name: this.companyNameFormControl
    });

    this.formSpeakerInterest = this.formBuilder.group({
      user_id: [''],
      company_id: [''],
      track_id: [''],
      title: [''],
      alt_email: [''],
      mobile_phone_number: [''],
      bio: [''],
      potential_topic: [''],
      notes: [''],
      format: [''],
      expertise_level: ['']
    });
  }

  triggerNameSearch(searchText: any) {
    if (!searchText || !(searchText instanceof Object)) {
      clearTimeout(this.userSearchDelay);
      this.userSearchDelay = setTimeout(() => {
        this.filteredNameChoices = this.filterUserChoicesObservable(searchText);
      }, 300);
    }
  }

  filterUserChoicesObservable(searchText: string): Observable<User[]> {
    let _filterChoicesObservable = new Observable<User[]>((observer) => {
      this.dswService.getUsers(searchText, this._csrf).subscribe((response: User[]) => {
        observer.next(response);
      });
    });
    return _filterChoicesObservable;
  }

  userAutocompleteSelected(event: MatAutocompleteSelectedEvent): void {
    console.log("autocompleteSelected ", event.option, event.option.value);
    this.formUser.patchValue(event.option.value)
    this.speakerInterst.user = event.option.value;
    this.speakerInterst.user_id = this.speakerInterst.user.id;
    // this.userNameFormControl.setValue(this.searchText);
  }

  triggerCompanySearch(searchText: any) {
    if (!searchText || !(searchText instanceof Object)) {
      clearTimeout(this.companySearchDelay);
      this.companySearchDelay = setTimeout(() => {
        this.filteredCompanyChoices = this.filterCompanyChoicesObservable(searchText);
      }, 300);
    }
  }

  filterCompanyChoicesObservable(searchText: string): Observable<Company[]> {
    let _filteredCompanyChoices = new Observable<Company[]>((observer) => {
      this.dswService.getCompanies(searchText, this._csrf).subscribe((response: Company[]) => {
        observer.next(response);
      });
    });
    return _filteredCompanyChoices;
  }

  companyAutocompleteSelected(event: MatAutocompleteSelectedEvent): void {
    console.log("autocompleteSelected ", event.option, event.option.value);
    this.formCompany.patchValue(event.option.value)
    this.speakerInterst.company = event.option.value;
    this.speakerInterst.company_id = this.speakerInterst.company.id;
    // this.userNameFormControl.setValue(this.searchText);
  }

  loadFormData() {
    console.log("id: ", this.id, " csrf", this.csrf);
    this.dswService.getSubmissionSelectValues(this._csrf).subscribe((results: SelectValues) => {
      this.selectValues = results;
      console.log("Values results", results);
    });
    if (this._id && this._csrf) {
      this.dswService.getSpeakerInterest(this._id, this._csrf).subscribe((results: SpeakerInterest) => {
        this.speakerInterst = results;
        console.log("SI results", results);
        this.formUser.patchValue(results.user);
        this.formCompany.patchValue(results.company);
        delete this.speakerInterst.user;
        delete this.speakerInterst.company;
        this.formSpeakerInterest.patchValue(this.speakerInterst);
      });
    }
  }

  checkCompanyId(event) {
    console.log("checkCompanyId", event);
    this.dswService.getCompanies(event.target.value, this._csrf).subscribe((response: Company[]) => {
      if (response.length > 0) {
        console.log("Found company", response);
      }
      else {
        console.log("No company found, prompt for create", event.target.value);
        let companyJson = { name: event.target.value };
        let dialogRef: MatDialogRef<CreateCompanyDialogComponent, any> = this.dialog.open(CreateCompanyDialogComponent, { data: companyJson });
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed', result);
          if (result == 'Ok') {
            let formData: FormData = this.dswService.jsonToForm('company', companyJson);
            this.dswService.postFormData("/admin/companies", formData, this._csrf).subscribe((response) => {
              console.log("Response from create company", response);
            });
          }
        });
      }
    });


  }
  showValue() {
    console.log(this.formSpeakerInterest.value);
    let formData: FormData = this.dswService.jsonToForm('speaker_interest', this.formSpeakerInterest.value);
    formData.append("_method", "patch");

    this.dswService.postFormData(this.submitUrl + "/" + this.id, formData, this._csrf).subscribe((response) => {
      console.log("Response from patch", response);
      if (response.body && response.body['redirect']) {
        window.location = response.body['redirect'];
        // this.location.go(response.body['redirect']);
      }
    });

  }
}
