 <mat-toolbar mat-dialog-title class="fukmdcbs">
    <mat-toolbar-row>
      <span>Edit vCard</span>
    </mat-toolbar-row>
  </mat-toolbar>
<div mat-dialog-content>
	<form [formGroup]="formVCard" class="formUser" *ngIf="formVCard">
    <div class="row" >
     <div class="col instructions" >
      <p *ngIf="generated">This minimal vcard was generated from your user profile.</p>  
      <p>You can edit the fields or you can upload/drop a vCard.
      <button mat-button (click)="file.click()">Pick vCard</button>
      </p>
      <input type="file"
                   #file
                   [multiple]="false"
                   (change)="FileChange($event)"
                   style="display:none" />     </div>  
    </div>
    <div class="row">
     <div class="col avatar" >
      <img [src]="avatar" *ngIf="avatar"/>
      <image-placeholder *ngIf="!avatar"></image-placeholder>
     </div>
     
     <div class="col">
	     <div class="row">
		     <div class="col fullName">
		       <mat-form-field >
		         <mat-label >Full Name</mat-label>
		         <input matInput placeholder="Full Name" formControlName="fullName" >
		       </mat-form-field>
		     </div>
		   </div>
		    <div class="row">
		     <div class="col title">
		       <mat-form-field >
		         <mat-label >Title</mat-label>
		         <input matInput placeholder="Title" formControlName="title" >
		       </mat-form-field>
		     </div>
		   </div>
        <div class="row">
         <div class="col org">
           <mat-form-field >
             <mat-label >Organization</mat-label>
             <input matInput placeholder="Organization" formControlName="org" >
           </mat-form-field>
         </div>
       </div>
		 </div>
	  </div>
    <div class="row">
     <div class="col email1">
       <mat-form-field >
         <mat-label >EMail </mat-label>
         <input matInput placeholder="EMail" formControlName="email1" >
       </mat-form-field>
     </div>
     <div class="col email2">
       <mat-form-field >
         <mat-label >Other EMail</mat-label>
         <input matInput placeholder="Other EMail" formControlName="email2" >
       </mat-form-field>
     </div>
   </div>
    <div class="row">
     <div class="col url1">
       <mat-form-field >
         <mat-label >URL </mat-label>
         <input matInput placeholder="URK" formControlName="url1" >
       </mat-form-field>
     </div>
     <div class="col url2">
       <mat-form-field >
         <mat-label >Other URL</mat-label>
         <input matInput placeholder="Other URL" formControlName="url2" >
       </mat-form-field>
     </div>
   </div>
    <div class="row">
     <div class="col prefix">
       <mat-form-field >
         <mat-label >Prefix</mat-label>
         <input matInput placeholder="Prefix" formControlName="prefix" >
       </mat-form-field>
     </div>
     <div class="col given">
       <mat-form-field >
         <mat-label >Given Name</mat-label>
         <input matInput placeholder="Given Name" formControlName="givenName" >
       </mat-form-field>
     </div>
     <div class="col middle">
       <mat-form-field >
         <mat-label >Middle Name</mat-label>
         <input matInput placeholder="Middle Name " formControlName="middleName" >
       </mat-form-field>
     </div>
     <div class="col family">
       <mat-form-field >
         <mat-label >Last Name</mat-label>
         <input matInput placeholder="Last Name " formControlName="lastName" >
       </mat-form-field>
     </div>
     <div class="col suffix">
       <mat-form-field >
         <mat-label >Suffix</mat-label>
         <input matInput placeholder="Suffic" formControlName="suffix" >
       </mat-form-field>
     </div>
   </div>
    <div class="row">
     <div class="col po">
       <mat-form-field >
         <mat-label >PO Box</mat-label>
         <input matInput placeholder="PO Box" formControlName="poBox" >
       </mat-form-field>
     </div>
     <div class="col addr">
       <mat-form-field >
         <mat-label >Address</mat-label>
         <input matInput placeholder="Address" formControlName="address" >
       </mat-form-field>
     </div>
   </div>
    <div class="row">
     <div class="col addr2">
       <mat-form-field >
         <mat-label >Address2</mat-label>
         <input matInput placeholder="Address 2" formControlName="address2" >
       </mat-form-field>
     </div>
   </div>
    <div class="row">
     <div class="col city">
       <mat-form-field >
         <mat-label >City</mat-label>
         <input matInput placeholder="City" formControlName="city" >
       </mat-form-field>
     </div>
     <div class="col state">
       <mat-form-field >
         <mat-label >State</mat-label>
         <input matInput placeholder="State" formControlName="state" >
       </mat-form-field>
     </div>
     <div class="col zip">
       <mat-form-field >
         <mat-label >Zip</mat-label>
         <input matInput placeholder="Zip" formControlName="zipCode" >
       </mat-form-field>
     </div>
     <div class="col country">
       <mat-form-field >
         <mat-label >Country</mat-label>
         <input matInput placeholder="Country" formControlName="country" >
       </mat-form-field>
     </div>
   </div>
    <div class="row">
   </div>
	</form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="resetCard()">Reset Card</button>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button (click)="updateCard()" cdkFocusInitial >Ok</button>
</div>
<div class="drop-zone">
</div>
 