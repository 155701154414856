<div class="chip-title" *ngIf="targetAudience && targetAudience.whoFor">
  <h3>Who is this for?:</h3>
  <mat-chip-set>
    <mat-chip color="primary" *ngFor="let chip of targetAudience.whoFor">{{chip}}</mat-chip>
  </mat-chip-set>    
</div>
<div class="chip-title" *ngIf="expertizeLevel">
  <h3>Expertise Level:</h3>
  <mat-chip-set>
    <mat-chip color="primary">
      <div style="display: flex; flex-directory: row;">
      <track-icon [track]="expertizeLevel"></track-icon>
      {{expertizeLevel}}
      </div>
     </mat-chip>
  </mat-chip-set>    
</div>
<div class="chip-title" *ngIf="targetAudience && targetAudience.tags">
  <h3>Tags:</h3>
  <mat-chip-set>
    <mat-chip color="primary" *ngFor="let chip of targetAudience.tags">{{chip}}</mat-chip>
  </mat-chip-set>    
</div>