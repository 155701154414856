<svg
   width="30px"
   height="30px"
   viewBox="0 0 30 30"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg">
  <g>
    <rect
       style="fill:#000000;fill-opacity:1;stroke-width:0.264583"
       width="18"
       height="18"
       x="12.213203"
       y="-9"
       transform="rotate(45)" />
  </g>
</svg>
