// Typescript version of vcf written by Jonas Hermsmeier
// https://github.com/jhermsmeier/node-vcf/tree/master

import { Property } from './Property';
import { ParseLines } from './ParseLines';
import * as foldLine from 'foldline';
export type VCardProperty =  Property | Property[];
export class VCard {
  data: { [key: string]: VCardProperty } = {};
  versions = ['2.1', '3.0', '4.0'];
  version = this.versions[this.versions.length - 1];

  constructor() {

  }

  static mimeType = 'text/vcard';
  static extension = '.vcf';
  static EOL = '\r\n'

  normalize(input: string) {
    return (input + '')
      // Trim whitespace
      .replace(/^[\s\r\n]+|[\s\r\n]+$/g, '')
      // Trim blank lines
      .replace(/(\r\n)[\x09\x20]?(\r\n)|$/g, '$1')
      // Unfold folded lines
      .replace(/\r\n[\x20\x09]/g, "");
  }

  isSupported(version: string) {
    return /^\d\.\d$/.test(version) && this.versions.indexOf(version) !== -1;
  }

  static fromJSON(jcard) {

    jcard = typeof jcard === 'string' ?
      JSON.parse(jcard) : jcard

    if (jcard == null || !Array.isArray(jcard))
      return new VCard()

    if (!/vcard/i.test(jcard[0]))
      throw new Error('Object not in jCard format')

    var card = new VCard()

    jcard[1].forEach((prop) => {
      card.addProperty(Property.fromJSON(prop))
    })

    return card

  }

  format(version?: string) {

    version = version || this.version ||
      this.versions[this.versions.length - 1]

    if (!this.isSupported(version))
      throw new Error('Unsupported vCard version "' + version + '"')

    var vcf = []

    vcf.push('BEGIN:VCARD')
    vcf.push('VERSION:' + version)

    var props = Object.keys(this.data)
    var prop: Property | Property[];

    for (var i = 0; i < props.length; i++) {
      if (props[i] === 'VERSION') continue;
      let prop = this.data[props[i]];

      if (Array.isArray(prop)) {
        for (var k = 0; k < prop.length; k++) {
          if (prop[k].isEmpty()) continue
          vcf.push(foldLine(prop[k].toString(version), 75))
        }
      } else if (!prop.isEmpty()) {
        vcf.push(foldLine(prop.toString(version), 75))
      }
    }

    vcf.push('END:VCARD')

    return vcf.join(VCard.EOL)

  }

  get(key: string): VCardProperty {
    return this.data[key];
  }

  add(key: string, value: string, params?: any) {
    var prop = new Property(key, value, params)
    this.addProperty(prop)
    return this
  }


  addProperty(prop: Property) {

    var key = prop.field

    if (Array.isArray(this.data[key])) {
      (this.data[key] as Property[]).push(prop)
    } else if (this.data[key] != null) {
      this.data[key] = [this.data[key] as Property, prop]
    } else {
      this.data[key] = prop
    }

    return this

  }

  parse(value: string): VCard {

    // Normalize & split
    var lines = this.normalize(value)
      .split(/\r\n/g)

    // Keep begin and end markers
    // for eventual error messages
    var begin = lines[0]
    var version = lines[1]
    var end = lines[lines.length - 1]

    // Multiple used RegExp's
    const regexp_version = /VERSION:\d\.\d/i

    if (!/BEGIN:VCARD/i.test(begin))
      throw new SyntaxError('Invalid vCard: Expected "BEGIN:VCARD" but found "' + begin + '"')

    if (!/END:VCARD/i.test(end))
      throw new SyntaxError('Invalid vCard: Expected "END:VCARD" but found "' + end + '"')

    if (!regexp_version.test(version)) {
      // VERSION mostly follows BEGIN, but it has only mandatory follow BEGIN, for version 4.0
      // Let's do the more expensive lookup for the lesser used version variant
      if (!(version = lines.find(line => regexp_version.test(line))))
        throw new SyntaxError('Invalid vCard: Expected "VERSION:\\d.\\d" but none found')
      // TODO: Do we need to throw an error if version = 4.0 or != 3.0|2.1? (because not followed BEGIN)?
    }

    this.version = version.substring(8, 11)

    if (!this.isSupported(this.version))
      throw new Error('Unsupported version "' + this.version + '"')

    this.data = new ParseLines(lines).data;
    return this;
  }

  toString(version: string): string {
    version = version || this.version;
    return this.format(version);
  }

  /**
   * Format the card as jCard
   * @param {String} version='4.0'
   * @return {Array} jCard
   */
  toJCard(version: string) {

    version = version || '4.0'

    var keys = Object.keys(this.data)
    var data = [['version', {}, 'text', version]]
    var prop = null

    for (var i = 0; i < keys.length; i++) {
      if (keys[i] === 'version') continue;
      prop = this.data[keys[i]]
      if (Array.isArray(prop)) {
        for (var k = 0; k < prop.length; k++) {
          data.push(prop[k].toJSON())
        }
      } else {
        data.push(prop.toJSON())
      }
    }

    return ['vcard', data]

  }

  toJSON() {
    return this.toJCard(this.version)
  }


}