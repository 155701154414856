<svg
   width="30px"
   height="30px"
   viewBox="0 0 30 30"
   class="icon"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg">
  <g>
    <rect
       style="fill:#0000ff;fill-opacity:1;stroke-width:0.264583"
       width="20"
       height="20"
       x="5"
       y="-25"
       transform="rotate(90)" />
  </g>
</svg>
